.wizard-heading {
  text-align: left;
  padding: 0;
  margin: 3% 0;
  font-size: larger;
  font-weight: 500;
}
.wizard-infotext {
  margin: 5% 0;
  font-size: medium;
}
.wizard-vertical-line {
  border-left: 1px solid grey;
  height: 300px;
}
.border-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wizard-or {
  padding: 0 4%;
  font-weight: 100;
  font-size: x-large;
}

.wizard-grid {
  margin-bottom: 3%;
}

.wizard-heading-2 {
  padding-top: 2%;
  margin: 0;
  padding-bottom: 4%;
  font-size: large;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wizard-icon {
  margin-right: 2%;
}

.wizard-progress-icon {
  margin-left: 2%;
}

.wizard-action {
  display: flex;
  justify-content: space-between !important;
}

.wizard-loading-text {
  text-align: center;
  font-weight: 300;
  font-size: larger;
  margin: 0;
  margin-top: 3%;
  padding: 0;
}

.deployment-animation {
  height: 50vh;
}

.small-preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10%;
}

.wizard-wrapper {
  margin-top: 2%;
}

.wizard-list-container {
  height: 61vh;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 2%;
}

.wizard-release-container {
  height: 45vh;
  overflow: auto;
  overflow-x: hidden;
}

.wizard-publish {
  text-align: center;
  margin: 3% 0;
}

.selection-text{
  padding-top: 4%;
}

.config-wizard-wrapper{
  width: 80%;
  margin: 5% auto;
}