.rule {
    margin-top: 18px;
}

div.rule-fields, div.rule-operators, div.rule-values {
    margin-right: 12px;
    margin-left: 12px
}

button.ruleGroup-addRule {
    margin-left: 12px;
    color: var(--primary-color);
    background-color: transparent;
}

button.ruleGroup-addGroup {
    margin-left: 12px;
}

button.rule-remove {
    margin-left: 12px;
    color: var(--primary-color);
    background-color: transparent;
}

.queryBuilder-dragHandle > svg {
    margin-top: 6px;
    cursor: grab;
}

.ruleGroup-combinators {
    margin-right: 12px;
}

button.ruleGroup-addRule:hover, button.rule-remove:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(239, 108, 0, 0.04);
    border: 1px solid var(--primary-color);
}

div.rule-value:after {
    border-bottom: 2px solid var(--primary-color);
}