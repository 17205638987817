.details-heading {
  padding-bottom: 2%;
}

.vehicle-list-wrapper {
  height: 566px;
  overflow: auto;
}

.schema-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3%;
}

.schema-button{
  display: flex;
  justify-content: flex-start;
}

.schema-button button:nth-child(2) {
  margin-left: 3%
};

.shadow-list{
  padding: 0;
  margin: 0;
}

.shadow-items{
  list-style-type: none;
  padding: 5% 0;
  border-bottom: 1px solid #eee;
}

.shadow-refresh{
  float: right;
  padding: 0 !important;
}

.door-lock{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4% 0;
}

.door-button{
  display: flex;
  align-items: center;
  justify-content: center;
}

.door-text{
  padding-left: 5%;
}

.topview-vehicle{
  margin-top: 1%;
  display: flex;
  justify-content: center;
  height: 300px;
}

.shadow-matched{
  background-color: #e4ffe4;
}

.shadow-mismatch{
  background-color: #ffebeb;
}