.user-account-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.role-menu-list div.MuiPaper-root {
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.role-menu-list ul.MuiList-root {
  padding: 0;
}

.role-menu-list li div {
  padding-right: 5%;
}

.role-title {
  font-size: 14px;
}

.account-username {
  padding-left: 14px;
}

.user-confirmed-icon {
  margin-left: 5%;
}
