.campaign-date-picker div.MuiFormControl-root {
  margin-top: 10%;
}
.helper-text {
  font-size: small;
  padding: 0;
  margin: 5px 0;
}
.info-text div {
  font-size: small;
  margin: 0 1% 2% 1%;
}

.job-document-container {
  padding: 1%;
  background-color: #f2f2f2;
}

.job-status-progress {
  margin-left: 3%;
}

.job-status-progress-border {
  border-left: #eeeeee 5px solid;
  margin-bottom: 3%;
}