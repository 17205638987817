body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*remove blue filled on text boxes*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

:root {
  --primary-color: #ef6c00;
}



.amplify-tabs-item[data-state="active"] {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.amplify-button[data-variation="link"]:hover {
  background-color: var(
    --amplify-components-button-link-hover-background-color
  );
  border-color: var(--amplify-components-button-link-hover-border-color);
  color: var(--primary-color);
}

.amplify-button[data-variation="link"] {
  background-color: var(--amplify-components-button-link-background-color);
  color: var(--primary-color);
}


